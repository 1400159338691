<template>
    <div class="stock-item-type-add">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.bannerCreate')"
            :title="$t('messages.bannerCreate')"
            @submitPressed="submitPressed"
        />
        <banner-form :bannerObject="banner" :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import BannerForm from './components/BannerForm.vue'

export default {
    components: {
        TitleBar,
        BannerForm
    },
    data() {
        return {
            action: null,
            banner: {}
        }
    },
    methods: {
        submitPressed() {
            this.action = 'add'
        }
    }
}
</script>
