<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        :class="{ row, error: v$.banner.name.$errors.length }"
                        :label="$t('forms.name')"
                        label-for="name"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input v-model="banner.name" id="name" type="text"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.banner.name.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.banner.description.$errors.length }"
                        :label="$t('forms.description')"
                        label-for="description"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-textarea v-model="banner.description" id="description"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.banner.description.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.levelSelect.$errors.length }"
                        :label="$t('forms.level')"
                        label-for="h-level"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-level"
                            v-model="levelSelect"
                            :options="levels"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.levelSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.range.$errors.length }"
                        :label="$t('forms.date')"
                        label-for="h-branch"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <datepicker
                            id="range"
                            v-model="range"
                            :enableTimePicker="false"
                            range
                            format="dd.MM.yyyy"
                            class="form-input"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.range.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.enabled')"
                        label-for="h-enabled"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-checkbox
                            v-model="banner.onOff"
                            id="h-enabled"
                            switch
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import "@vuepic/vue-datepicker/dist/main.css";
import Datepicker from "@vuepic/vue-datepicker";
import vSelect from "vue-select";

export default {
    components: {
        Datepicker,
        vSelect
    },
    props: {
        bannerObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            range: {required},
            levelSelect: {required},
            banner: {
                name: {required},
                description: {required},
            },
        };
    },
    data() {
        return {
            range: [],
            banner: this.bannerObject,
            levelSelect: {},
            levels: []
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    created() {
        if (this.banner.fromDate) {
            this.range[0] = this.banner.fromDate;
        }
        if (this.banner.toDate) {
            this.range[1] = this.banner.toDate;
        }
        this.getEnumList(['banner_level'], this.levels);
        if ("id" in this.banner) {
            this.levelSelect = {
                label: this.banner.level,
                id: this.banner.level,
            };
        }
        console.log(this.levelSelect)
        console.log(this.banner)
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                this.banner.fromDate = this.range[0];
                this.banner.toDate = this.range[1];
                this.banner.level = this.levelSelect.id;
                if (this.action === "add") {
                    this.create(
                        this.$Banners,
                        this.banner,
                        this.$t("messages.bannerCreated"),
                        null,
                        this.success,
                        this.error
                    );
                }
                if (this.action === "update") {
                    this.$emit("clearAction");
                    this.update(
                        this.$Banners,
                        this.banner.id,
                        this.banner,
                        this.$t("messages.bannerUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },
        success() {
            this.$router.push(`/settings/banners`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
